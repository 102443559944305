import React from "react";
import {
  Grid, Box, Container, makeStyles, Typography, ListItem,
  List,Link
} from "@material-ui/core";
import { AiOutlineTwitter } from "react-icons/ai";
import { AiOutlineInstagram } from "react-icons/ai";
import { FaDiscord } from "react-icons/fa";
import { useHistory, Link as RouterLink } from "react-router-dom";
import Scroll from "react-scroll";
const ScrollLink = Scroll.Link;

// import {} from "react-feather";
const useStyles = makeStyles((theme) => ({
  footerSection: {
    position: "relative",
    padding: " 20px 0px",
    position: "relative",
    backgroundColor: "#000000",
    backgroundSize: "30px 30px",
    backgroundImage: "radial-gradient(circle,rgb(31,31,31) 1px,rgba(29,29,29,0) 1px)",
    zIndex: "99",
    // [theme.breakpoints.up("sm")]: {
    //   paddingTop: theme.spacing(4),
    // },
    // [theme.breakpoints.up("md")]: {
    //   paddingTop: theme.spacing(4),
    // },
    "& .mainBox": {
      padding: "2vw",
      [theme.breakpoints.down("xs")]: {
        padding: "0px",
      },
    },
    "& p": {
      fontSize: "14px",
      margin: "0",
      padding: "10px 0",
      cursor: "pointer",
    },
    "& a": {
      fontSize: "18px",
      margin: "0",
      cursor: "pointer",
      padding: "10px 0",
      textDecoration: "none",
      color: "#989797",
      "&:hover": {
        textDecoration: "none",
      },
    },
  },
  menuul: {
    display: "flex",
    listStyle: "none",
    padding: "0",
    alignItems: "center",
    margin: "0",
    justifyContent: "end",
    [theme.breakpoints.down("xs")]: {
      justifyContent: "center",
      marginBottom: "5px",
    },
    "& div": {
      width: "41px",
      height: "41px",
      borderRadius: "50%",
      color: "#fff",
      marginLeft: "15px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "20px",
      cursor: "pointer",
      backgroundColor: "#929192",
      border: "2px solid #929192",
      [theme.breakpoints.down("xs")]: {
        margin: "0 5px",
      },
      "&:hover": {
        background: "transparent",
        color: "#fff",
      },
    },
  },
  footerBg: {
    position: "absolute",
    bottom: "0",
    width: "100%",
    left: "0",
  },

  borderBottmo: {
    overflow: "hidden",
    background: "#212328",
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6),
    [theme.breakpoints.down("md")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },

  largeIcon: {
    width: 18,
    height: 18,
    marginRight: "8px",
  },
  icons: {
    justify: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justify: "center",
    },
  },
  inputBox: {
    position: "relative",
  },
  footerBgImg: {
    position: "absolute",
    bottom: 0,
    left: 0,
    width: "100%",
    zIndex: "1",
  },
  textFild: {
    position: "relative",

    "& button": {
      position: "absolute",
      top: 0,
      right: 0,
      height: "100%",
      backgroundColor: "#000",
      minWidth: "90px",
      fontSize: "18px",
      fontWeight: "700",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#000",
      },
    },
  },

  bottomfooter: {
    margin: "0",
    background: "#000000",
    height: "40px",
    // padding:"20px",
    color: "#ffffffb5",
  },
  footerlogo: {
    textAlign: "left",
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
    "& h1": {
      fontSize: "42px",
      color: "#fff",
      [theme.breakpoints.down("md")]: {
        fontSize: "25px",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "15px",
      },
      "&:hover": {
        color: "#803d8a",
      },
    },
    "& span": {
      fontSize: "16px",
      color: "#fff",
      fontFamily: "URW Geometric",
      "&:hover": {
        color: "#803d8a",
      },
    },
  },
  footercenterimg: {
    backgroundSize: "cover",
    backgroundImage: "url(images/footercenter.gif)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    backgroundSize: "contain",
    overflow: "hidden",
    width: "100%",
    zIndex: " 99",
    minHeight: "75vh",
    [theme.breakpoints.down("md")]: {
      minHeight: "50vh",
    },
    [theme.breakpoints.only("xs")]: {
      minHeight: "17vh",
      alignItems: "center",
      backgroundSize: "contain",
    },
    "& h1": {
      fontSize: "42px",
      color: "#fff",
      [theme.breakpoints.down("md")]: {
        fontSize: "25px",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "15px",
      },
      "&:hover": {
        color: "#803d8a",
      },
    },
    "& span": {
      fontSize: "16px",
      color: "#fff",
      fontFamily: "URW Geometric",
      "&:hover": {
        color: "#803d8a",
      },
    },
  },
}));

export default function Liquidity() {
  const classes = useStyles();

  return (
    <Box mt={5} className={classes.footerSection}>
      <Container maxWidth="fixed">
        <Box className="mainBox">
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={3} sm={3} >
              <Box mt={2} className={classes.footerlogo}>
              <a target="_blank"  href="https://metaverse-staking.mobiloitte.org/#/app/Buycoin">
                     
                     <Typography variant="h1" align="center">
                      BUY
                     </Typography>
                     </a>
              </Box>
            </Grid>

            <Grid item xs={6} sm={6} align="center">
              <Box mt={2} className={classes.footercenterimg} align="center">
              <a target="_blank"  href="https://metaverse-staking.mobiloitte.org/#/app/stake-dashboard">
                     
                      <Typography variant="h1" align="center">
                       REGISTER
                      </Typography>
                      </a>
              </Box>
            </Grid>

            <Grid item xs={3} sm={3}>
              <Box mt={2} className={classes.footerlogo}>
                
                    
                      <a target="_blank"  href="https://metaverse-staking.mobiloitte.org/#/app/Network">
                     
                     <Typography variant="h1" align="center">
                      STAKE
                     </Typography>
                     </a>
                   
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box align="center">
          <img src="images/logo-Tarality.gif" style={{ maxWidth: "300px", }} />
          <Typography variant="body1" align="center">© 2022 Tarality Metaverse</Typography>
        </Box>
      </Container>



    </Box>
  );
}
